// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-summary-js": () => import("./../../../src/pages/summary.js" /* webpackChunkName: "component---src-pages-summary-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */),
  "component---src-templates-intros-js": () => import("./../../../src/templates/Intros.js" /* webpackChunkName: "component---src-templates-intros-js" */),
  "component---src-templates-text-js": () => import("./../../../src/templates/Text.js" /* webpackChunkName: "component---src-templates-text-js" */)
}

